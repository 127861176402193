<template>
  <div class="offer-details__main-block">
    <VOverlay v-if="_fetchingOffersStatus === requestStatus.PENDING">
      <VLoader />
    </VOverlay>
    <VCollapse :showLine="true">
      <template v-slot:header>
        <div class="offer-details__block-header">Targeting</div>
      </template>
      <template v-slot:body>
        <div class="offer-details__block-body">
          <div v-if="!_targeting">No targeting applied</div>
          <div v-else v-for="item in listTargeting" :key="item.id" class="offer-details__block-list mb-3">
            <div class="offer-details__title p-1 mb-1 text-semibold">{{ item.title }}</div>
            <template v-if="!_targeting.browsers.length">
              <VListIsEmpty />
            </template>
            <template v-else>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.browsers[item.id]">
                <div class="col color-grey-main">Browser</div>
                <div class="col">{{ _targeting.browsers[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.mobile_carriers[item.id]">
                <div class="col color-grey-main">Carrier</div>
                <div class="col">{{ _targeting.mobile_carriers[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.cities[item.id]">
                <div class="col color-grey-main">City</div>
                <div class="col">{{ _targeting.cities[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.countries[item.id]">
                <div class="col color-grey-main">Country</div>
                <div class="col">{{ _targeting.countries[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.brands[item.id]">
                <div class="col color-grey-main"> Device Brand</div>
                <div class="col">{{ _targeting.brands[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.device_types[item.id]">
                <div class="col color-grey-main">Device Type</div>
                <div class="col">{{ _targeting.device_types[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.dmas[item.id]">
                <div class="col color-grey-main">DMA</div>
                <div class="col">{{ _targeting.dmas[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.isps[item.id]">
                <div class="col color-grey-main">ISP</div>
                <div class="col">{{ _targeting.isps[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.languages[item.id]">
                <div class="col color-grey-main">Language</div>
                <div class="col">{{ _targeting.languages[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.os_versions[item.id]">
                <div class="col color-grey-main">OS Version</div>
                <div class="col">{{ _targeting.os_versions[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.platforms[item.id]">
                <div class="col color-grey-main">Platform</div>
                <div class="col">{{ _targeting.platforms[item.id].label }}</div>
              </div>
              <div class="offer-details__data d-flex mb-1 px-1" v-if="_targeting.regions[item.id]">
                <div class="col color-grey-main">Region</div>
                <div class="col">{{ _targeting.regions[item.id].label }}</div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </VCollapse>
  </div>
</template>

<script>
import VCollapse from "@/js/components/common/VCollapse";
import VListIsEmpty from '@/js/components/common/VListIsEmpty';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "Targeting",
  components: { VListIsEmpty, VCollapse },
  data() {
    return {
      requestStatus,
      offerId: Number(this.$route.params.id),
      listTargeting: [
        {
          id: 0,
          title: 'Included'
        },
        {
          id: 1,
          title: 'Excluded'
        }
      ]
    };
  },
  computed: {
    _fetchingOffersStatus() {
      return this.$store.state.offers.fetchingOffersStatus;
    },
    _offers() {
      return this.$store.state.offers.offers || [];
    },
    _offerById() {
      return this._offers.find((item) => item.network_offer_id === this.offerId);
    },
    _targeting() {
      if (!this._offers) return [];
      return this._offerById?.relationship?.ruleset;
    },
  },
  mounted() {
    if (!this._offers.length && this._fetchingOffersStatus === requestStatus.IDLE) this.getOffers();
  },
  methods: {
    async getOffers() {
      try {
        await this.$store.dispatch('offers/getOffers', {
          formData: {
            affiliate_status: '__all',
          },
          params: {
            order_field: '',
            order_direction: '',
          }
        });
      } catch (err) {
        console.log('=> ERR', err);
      }
    },
  }
};
</script>
<style lang="scss" scoped>

.offer-details {

  &__title {
    text-align: left;
    background-color: var(--blue-light);
  }

  &__block-list {
    width: 100%;
  }

  &__data {
    width: 100%;
  }

  .col {
    width: 50%;
    text-align: left;
  }
}

</style>