<template>
  <div class="search" ref="search">
    <div class="search__input-wrap">
      <icon-search/>
      <input
          class="search__input ml-1"
          type="text"
          v-model="search"
          :placeholder="placeholder"
          @input="selectOption(search)"
      />
      <div class="search__btn-clear" @click.prevent.stop="clearSearch">
        <icon-close color="#DDE4EB"/>
      </div>
    </div>
  </div>
</template>

<script>

import IconClose from "@/js/components/icons/IconClose";
import IconSearch from "@/js/components/icons/IconSearch";

export default {
  name: "VSearch",
  components: {IconSearch, IconClose},
  props: {
    initialValue: String,
    placeholder: String
  },
  data() {
    return {
      search: this.initialValue
    }
  },
  watch: {
    initialValue() {
      if (!Object.keys(this.initialValue).length) this.search = '';
    }
  },

  methods: {
    selectOption(value) {
      this.search = value
      this.$emit('input', value)
    },
    clearSearch() {
      this.search = '';
      this.$emit('clear');
    },
  }
}
</script>

<style scoped lang="scss">
.search {
  width: 100%;

  &.xs {
    max-width: 260px;
  }

  &.xl {
    max-width: 320px;
  }

  &.ml {
    max-width: 360px;
  }

  &__input-wrap {
    height: 40px;
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    outline: none;
    width: 100%;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
  }

  &__btn-clear {
    border-left: 2px solid var(--grey-light-1);
    padding-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 8px;
  }
}

</style>