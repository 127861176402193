<template>
  <div class="text-center text-italic">
    List is empty
  </div>
</template>

<script>
export default {
  name: "VListIsEmpty"
};
</script>

<style lang="scss" scoped>
</style>