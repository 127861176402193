<template>
  <div class="offer-details__main-block">
    <VOverlay v-if="_fetchingOfferStatsStatus === requestStatus.PENDING">
      <VLoader />
    </VOverlay>
    <VCollapse>
      <template v-slot:header>
        <div class="offer-details__block-header">Stats</div>
      </template>
      <template v-slot:body>
        <div class="offer-details__block-body">
          <div class="offer-details__block-header-items">
            <VDatePicker v-model="statsDatePicker" v-on:click.stop.prevent.native />
            <v-button type="small-blue-outline" class="btn-xl ml-2" @click.native.prevent.stop="onViewReport">
              View report
            </v-button>
          </div>
          <div v-if="_offerStats" class="table">
            <div class="table__tr" v-for="(row, index) in _statsData" :key="index">
              <div class="table__th text-semibold">{{ row.title }}</div>
              <div class="table__td">{{ row.value }}</div>
            </div>
          </div>
        </div>
      </template>
    </VCollapse>
  </div>
</template>

<script>
import VCollapse from "@/js/components/common/VCollapse";
import VDatePicker from "@/js/components/common/VDatePicker";
import moment from "moment";
import { requestStatus, toasted } from '@/js/utils/constants';

export default {
  name: "Stats",
  components: {
    VCollapse,
    VDatePicker,
  },
  data() {
    return {
      requestStatus,
      statsRequestData: {
        "columns": [
          {
            "column": "offer"
          }
        ],
        "query": {
          "filters": [
            {
              "filter_id_value": "94",
              "resource_type": "offer"
            }
          ]
        },
        "from": this.todayDate(),
        "to": this.todayDate(),
        "timezone_id": this.mainData?.caps_timezone_id || 67,
        "currency_id": this.mainData?.currency_id || 'EUR',
      },
      statsDatePicker: [],
    };
  },
  computed: {
    _mainData() {
      return this.$store.getters['offerDetails/mainData'];
    },
    _offerStats() {
      return this.$store.state.offerDetails.offerStats;
    },
    _fetchingOfferStatsStatus() {
      return this.$store.state.offerDetails.fetchingOfferStatsStatus;
    },
    _statsData: function () {
      return [
        {
          title: 'Revenue',
          value: this._offerStats?.summary?.revenue,
        },
        {
          title: 'Clicks',
          value: this._offerStats?.summary?.total_click,
        },
        {
          title: 'CV',
          value: this._offerStats?.summary?.cv,
        },
        {
          title: 'CVR',
          value: this._offerStats?.summary?.cvr,
        },
        {
          title: 'RPC',
          value: this._offerStats?.summary?.rpc,
        },
      ];
    },
  },
  mounted() {
    this.loadStats();
  },
  methods: {
    async loadStats() {
      this.statsRequestData.from = this.statsDatePicker[0] || this.todayDate();
      this.statsRequestData.to = this.statsDatePicker[1] || this.todayDate();

      try {
        await this.$store.dispatch('offerDetails/getOfferStats', this.statsRequestData);
      } catch (err) {
        console.log('=> ERR', err);
        this.$toasted.error(toasted.text.error);
      }
    },
    async onViewReport() {
      try {
        const { name, network_offer_id, offer_status } = this._mainData;

        const res = await this.$store.dispatch('offerDetails/getPermissionOnViewReport', network_offer_id);

        if (!res.error) {
          const offerData = {
            selectedOffers: [
              {
                id: network_offer_id,
                label: name,
                status: offer_status,
              }
            ],
            dateRange: this.statsDatePicker,
          };

          this.$router.push({
            name: 'Reports',
            path: '/reports',
            params: { offerData },
          });
        } else {
          this.$toasted.error(res.error);
        }
      } catch (err) {
        console.log('=> ERR', err);
        this.$toasted.error(toasted.text.error);
      }
    },
    todayDate() {
      return moment().format('YYYY-MM-DD');
    },
  },
  watch: {
    statsDatePicker() {
      this.loadStats();
    }
  }
};
</script>

<style lang="scss" scoped>

</style>