<template>
  <div class="offer-details__main-block">
    <VOverlay
      v-if="foundTableRowsIdsStatus === requestStatus.PENDING || _fetchingDownloadAllImagesStatus === requestStatus.PENDING"
    >
      <VLoader />
    </VOverlay>

    <VCollapse>
      <template v-slot:header>
        <div class="offer-details__block-header">Creatives</div>
      </template>
      <template v-slot:body>
        <div class="offer-details__block-body">
          <div class="offer-details__block-header-items">
            <v-button
              type="small-blue-outline"
              class="btn-ml ml-2"
              :disabled="!_creativesData.length"
              @click="onDownloadAll"
            >
              Download all
            </v-button>
          </div>
          <VSearch
            class="xs mb-2 ml-auto"
            placeholder="Search"
            :initial-value="searchFieldValue"
            @input="onSearchByTable($event, _creativesData)"
            @clear="onClearSearch"
          />
          <VTable
            :headCols="headCols"
            :bodyRows="_formattedTrackingList"
          >
            <template #col-3="{ row }">
              <img :src=row.resource_url width="40" height="30">
            </template>
            <template #col-4="{row}">
              <a :href=row.resource_url target="_blank">Download</a>
            </template>
          </VTable>
        </div>
      </template>
    </VCollapse>
  </div>
</template>

<script>
import VCollapse from "@/js/components/common/VCollapse";
import VSearch from "@/js/components/common/VSearch";
import VTable from "@/js/components/common/VTable";
import TableMixin from '@/js/mixins/tableMixin';
import { requestStatus, toasted } from '@/js/utils/constants';

export default {
  name: "Description",
  components: {
    VTable,
    VCollapse,
    VSearch
  },
  mixins: [TableMixin],
  props: {
    offerId: Number,
  },
  data() {
    return {
      requestStatus,
      headCols: [
        {
          id: 0,
          slug: 'id',
          name: 'ID',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 1,
          slug: 'name',
          name: 'Name',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 2,
          slug: 'creative_type',
          name: 'Type',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 3,
          slug: 'resource_url',
          name: 'Preview',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 4,
          slug: 'resource_url',
          name: 'Download',
          sort: false,
          orderBy: 'def'
        },
      ],
    };
  },
  computed: {
    _fetchingStatus() {
      return this.$store.state.offerDetails.fetchingOfferDetailsStatus;
    },
    _fetchingDownloadAllImagesStatus() {
      return this.$store.state.offerDetails.fetchingDownloadAllImagesStatus;
    },
    _mainData() {
      return this.$store.getters['offerDetails/mainData'];
    },
    _creativesData() {
      let creative = this._mainData?.relationship?.creatives?.entries[0];
      if (!creative) return [];
      return [
        {
          id: creative.network_offer_creative_id,
          name: creative.name,
          creative_type: creative.creative_type,
          resource_url: creative.resource_url,
        }
      ];
    },
    _formattedTrackingList() {
      return this._creativesData.filter((item) => this.foundTableRowsIds.includes(item.id));
    },
  },
  mounted() {
    if (this._fetchingStatus === requestStatus.SUCCESS) {
      this.setTableRowsIds(this._creativesData);
    }
  },
  methods: {
    async onDownloadAll() {
      try {
        const data = await this.$store.dispatch('offerDetails/downloadAllImages', this.offerId);
        window.open(data.url, '_blank');
      } catch (e) {
        this.$toasted.error(toasted.text.error);
      }
    },
    onClearSearch() {
      if (!this.searchFieldValue.length) return;
      this.onClearSearchByTable();
      this.setTableRowsIds(this._creativesData);
    },
  },
};
</script>