<template>
  <VLoader v-if="_fetchingOfferDetailsStatus === requestStatus.PENDING" />
  <div
    v-else-if="_fetchingOfferDetailsStatus === requestStatus.SUCCESS"
    class="offer-details"
  >
    <h2 class="title-page">
      <router-link to="/offers" class="offer-details__link">
        Offers
      </router-link>
      <icon-arrow class="offer-details__arrow" color="#1E70BB" />
      {{ _offerName }}
    </h2>
    <div class="offer-details__main">
      <div class="offer-details__main-row">
        <div class="offer-details__main-col">
          <MainData :offer-id="offerId" />
          <Description />
          <Tracking />
        </div>
        <div class="offer-details__main-col">
          <Stats />
          <Payout />
          <Targeing />
          <Creatives :offer-id="offerId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from "@/js/components/icons/IconArrow";
import MainData from "@/js/components/pages/offers/offerDeatils/MainData";
import Description from "@/js/components/pages/offers/offerDeatils/Description";
import Tracking from "@/js/components/pages/offers/offerDeatils/Tracking";
import Stats from "@/js/components/pages/offers/offerDeatils/Stats";
import Payout from "@/js/components/pages/offers/offerDeatils/Payouts";
import Targeing from "@/js/components/pages/offers/offerDeatils/Targeing";
import Creatives from "@/js/components/pages/offers/offerDeatils/Creatives";
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "OfferDetails",
  components: {
    Creatives,
    Targeing,
    Payout,
    Stats,
    Tracking,
    Description,
    IconArrow,
    MainData
  },
  data() {
    return {
      requestStatus,
      offerId: +this.$route.params.id || null,
    };
  },
  computed: {
    _fetchingOfferDetailsStatus() {
      return this.$store.state.offerDetails.fetchingOfferDetailsStatus;
    },
    _offerName() {
      return this.$store.getters['offerDetails/offerName'];
    },
  },
  async mounted() {
    if (this.offerId) {
      await this.$store.dispatch('offerDetails/getOfferDetails', this.offerId);
    }
  },
  destroyed() {
    this.$store.commit('offerDetails/RESET_STATUS', requestStatus.IDLE);
  }
};
</script>

<style lang="scss">
.offer-details {
  position: relative;

  &__link {
    text-decoration: none;
    color: var(--blue-main);
  }

  &__arrow {
    margin: 0 4px;
  }

  &__main {
    width: 100%;
  }

  &__main-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(520px, 1fr));
    grid-gap: 24px;

    @include media("max", "xl") {
      grid-template-columns: 470px minmax(420px, 1fr);
      grid-gap: 16px;
    }
  }

  &__main-block {
    position: relative;
  }

  &__block-header {
    font-size: 18px;
    font-weight: 600;
    position: relative;
  }

  &__block-header-items {
    display: flex;
    position: absolute;
    right: 20px;
    top: 16px;
    z-index: 2;

    @include media("max", "xl") {
      position: relative;
      right: auto;
      top: auto;
      justify-content: flex-end;
      margin-bottom: 24px;
    }
  }

  &__main-block {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: var(--main-box-shadow);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 24px;

    @include media("max", "xl") {
      margin-bottom: 16px;
    }
  }

  &__block-item-utc {
    background-color: var(--blue-light);
  }

  .col {
    max-width: 200px;
    width: 100%;
  }

  .table {
    padding-bottom: 15px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fill, auto);
    grid-auto-flow: column;
    grid-auto-columns: auto;
    overflow-x: auto;

    &__th {
      padding-bottom: 8px;
      border-bottom: 1px solid var(--blue-light);
    }
  }
}
</style>