<template>
  <div class="offer-details__main-block">
    <VOverlay v-if="_updatingApplicationQuestionnaireStatus === requestStatus.PENDING">
      <VLoader />
    </VOverlay>
    <VCollapse :showLine="true">
      <template v-slot:header>
        <div class="offer-details__block-header">Main Data</div>
      </template>
      <template v-slot:body>
        <div class="offer-details__block-body">
          <div class="d-flex mb-1">
            <div class="col color-grey-main">ID</div>
            <div class="col">{{ _mainData.network_offer_id }}</div>
          </div>
          <div class="d-flex mb-1">
            <div class="col color-grey-main">Name</div>
            <div class="col">{{ _mainData.name }}</div>
          </div>
          <div class="d-flex mb-1">
            <div class="col color-grey-main">Channels</div>
            <div class="col" v-if="_channelsCount">
              <div class="row" v-for="(channel, index) in _channels" :key="index">
                <div class="col">
                  <v-button type="small-blue-outline" class="btn-xs" :disabled="false">
                    {{ channel.name }}
                  </v-button>
                </div>
              </div>
            </div>
            <div class="col" v-else>-</div>
          </div>
          <div class="d-flex mb-1">
            <div class="col color-grey-main">Approval</div>
            <div
              v-if="_mainData.relationship.offer_affiliate_status === 'require_approval'"
              class="col"
            >
              <v-button
                type="small-blue"
                class="btn-ml"
                @click="onRequestApprove"
              >
                Request Approval
              </v-button>
            </div>
            <div
              v-else
              class="col"
            >
              {{ offerAffiliateStatus[_mainData.relationship.offer_affiliate_status] }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="col color-grey-main">Preview URL</div>
            <div class="col">
              <a v-if="_mainData.preview_url" :href="_mainData.preview_url">{{ _mainData.thumbnail_url }}</a>
              <span v-else>N/A</span>
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="col color-grey-main">Valid until</div>
            <div class="col">N/A</div>
          </div>
          <div class="d-flex mb-1">
            <div class="col color-grey-main">Currency</div>
            <div class="col">{{ _mainData.currency_id }}</div>
          </div>
          <div class="d-flex mb-1">
            <div class="col color-grey-main">App identifier</div>
            <div class="col">N/A</div>
          </div>
          <div class="d-flex mb-1">
            <div class="col color-grey-main">Blocked variables</div>
            <div class="col">
              <v-button
                type="small-blue"
                class="btn-ml"
                :disabled="true"
              >
                Download
              </v-button>
            </div>
          </div>
        </div>
      </template>
    </VCollapse>
  </div>
</template>

<script>
import VCollapse from "@/js/components/common/VCollapse";
import { requestStatus, offerAffiliateStatus, toasted } from '@/js/utils/constants';

export default {
  name: "MainData",
  components: { VCollapse },
  props: {
    offerId: Number
  },
  data() {
    return {
      requestStatus,
      offerAffiliateStatus,
    };
  },
  computed: {
    _updatingApplicationQuestionnaireStatus() {
      return this.$store.state.offers.updatingApplicationQuestionnaireStatus;
    },
    _mainData() {
      return this.$store.getters['offerDetails/mainData'];
    },
    _channels() {
      if (!this._mainData) {
        return [];
      }
      return this._mainData?.relationship?.channels?.entries || [];
    },
    _channelsCount() {
      if (!this._mainData) {
        return [];
      }
      return +this._mainData.relationship?.channels?.total > 0;
    }
  },
  methods: {
    async onRequestApprove() {
      try {
        await this.$store.dispatch('offers/getApplicationQuestionnaire', this.offerId);
        this.$toasted.success(toasted.text.success);
      } catch (err) {
        console.log('=> ERR:', err);
        this.$toasted.error(toasted.text.error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-details__main-block {
  position: relative;
}
</style>