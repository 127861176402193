<template>
  <div class="offer-details__main-block">
    <VCollapse :showLine="true">
      <template v-slot:header>
        <div class="offer-details__block-header">Tracking & control</div>
      </template>
      <template v-slot:body>
        <div class="offer-details__block-body">
          <div class="offer-details__block-item-utc d-flex justify-content-between p-1 mb-3">
            <div>Caps</div>
            <div>Enforcing specific timezone: ({{_timezoneOffset}}) {{ _timezoneName }}</div>
          </div>
          <div class="table">
            <div class="table__tr">
              <div class="table__th text-semibold">Type</div>
              <div class="table__td">Clicks</div>
              <div class="table__td">Conversions</div>
              <div class="table__td">Revenue</div>
            </div>
            <div class="table__tr">
              <div class="table__th text-semibold">Daily</div>
              <div class="table__td">{{_mainData.daily_click_cap || '-'}}</div>
              <div class="table__td">{{_mainData.daily_conversion_cap || '-'}}</div>
              <div class="table__td">{{_mainData.daily_payout_cap || '-'}}</div>
            </div>
            <div class="table__tr">
              <div class="table__th text-semibold">Weekly</div>
              <div class="table__td">{{_mainData.weekly_click_cap || '-'}}</div>
              <div class="table__td">{{_mainData.weekly_conversion_cap || '-'}}</div>
              <div class="table__td">{{_mainData.weekly_payout_cap || '-'}}</div>
            </div>
            <div class="table__tr">
              <div class="table__th text-semibold">Monthly</div>
              <div class="table__td">{{_mainData.monthly_click_cap || '-'}}</div>
              <div class="table__td">{{_mainData.monthly_conversion_cap || '-'}}</div>
              <div class="table__td">{{_mainData.monthly_payout_cap || '-'}}</div>
            </div>
            <div class="table__tr">
              <div class="table__th text-semibold">Global</div>
              <div class="table__td">{{_mainData.global_click_cap || '-'}}</div>
              <div class="table__td">{{_mainData.global_conversion_cap || '-'}}</div>
              <div class="table__td">{{_mainData.global_payout_cap || '-'}}</div>
            </div>
          </div>
        </div>
      </template>
    </VCollapse>
  </div>
</template>

<script>
import VCollapse from "@/js/components/common/VCollapse";

export default {
  name: "Tracking",
  components: {VCollapse},
  computed: {
    _mainData() {
      return this.$store.getters['offerDetails/mainData'];
    },
    _timezoneOffset() {
      return this.$store.getters["meta/timezoneById"](this._mainData?.caps_timezone_id)?.offset?.replace('UTC', 'GMT')
    },
    _timezoneName() {
      return this.$store.getters["meta/timezoneById"](this._mainData?.caps_timezone_id)?.timezone
    },
  }
}
</script>
<style>
</style>