<template>
  <div class="offer-details__main-block">
    <VCollapse :showLine="true">
      <template v-slot:header>
        <div class="offer-details__block-header">Description</div>
      </template>
      <template v-slot:body>
        <div class="offer-details__block-body">
          <div class="offer-details__main" readonly v-if="_isHtml">{{ _text }}</div>
          <div class="offer-details__main text-center color-grey-main" readonly v-else> No data to display</div>
        </div>
      </template>
    </VCollapse>
  </div>
</template>

<script>
import VCollapse from "@/js/components/common/VCollapse";

export default {
  name: "Description",
  components: {VCollapse},
  computed: {
    _text() {
      return this.$store.getters['offerDetails/description'].value;
    },
    _isHtml() {
      return !this.$store.getters['offerDetails/description'].is_description_plain_text;
    }
  }
}
</script>