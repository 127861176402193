import { requestStatus } from '@/js/utils/constants';

export default {
  data() {
    return {
      searchFieldValue: '',
      foundTableRowsIds: [],
      foundTableRowsIdsStatus: requestStatus.IDLE,
      foundTableRowsIdsTimeout: null,
    };
  },
  methods: {
    setTableRowsIds(defaultList) {
      this.foundTableRowsIds = defaultList.map((item) => item.id);
    },
    onSearchByTable(value, defaultList) {
      this.searchFieldValue = value.toLowerCase();

      clearTimeout(this.foundTableRowsIdsTimeout);

      this.foundTableRowsIdsTimeout = setTimeout(() => {
        this.foundTableRowsIdsStatus = requestStatus.PENDING;

        const currentRows = defaultList.filter((row) => {
          // eslint-disable-next-line no-unused-vars
          const { id, ...properties } = row;
          const textContent = Object.values(properties).map((value) => value).join(' ');
          return textContent.toLowerCase().includes(this.searchFieldValue);
        });

        this.foundTableRowsIds = currentRows.map((item) => item.id);

        setTimeout(() => {
          this.foundTableRowsIdsStatus = requestStatus.SUCCESS;
        }, 500);
      }, 1000);
    },
    onClearSearchByTable() {
      this.searchFieldValue = '';
    },
  }
};
