<template>
  <div class="offer-details__main-block">
    <VCollapse :showLine="true">
      <template v-slot:header>
        <div class="offer-details__block-header">Payout</div>
      </template>
      <template v-slot:body>
        <div class="offer-details__block-body">
          <VTable
            :headCols="headCols"
            :bodyRows="_payoutData"
          >
            <template #col-3="{}">
              0
            </template>
            <template #col-4="{}">
              0
            </template>
            <template #col-5="{}">
              N/A
            </template>
            <template #col-6="{row}">
              {{ row.is_enforce_caps || '-' }}
            </template>
            <template #col-7="{ }">
              -
            </template>
          </VTable>
        </div>
      </template>
    </VCollapse>
  </div>
</template>

<script>
import VCollapse from "@/js/components/common/VCollapse";
import VTable from "@/js/components/common/VTable";

export default {
  name: "Payout",
  components: {
    VTable,
    VCollapse
  },
  data() {
    return {
      headCols: [
        {
          id: 0,
          slug: 'entry_name',
          name: 'Name',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 1,
          slug: 'payout_type',
          name: 'Payout Type',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 2,
          slug: 'payout_amount',
          name: 'Payout',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 3,
          slug: '',
          name: 'Valid Between',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 4,
          slug: '',
          name: 'Variables',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 5,
          slug: '',
          name: 'Rules',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 6,
          slug: 'is_enforce_caps',
          name: 'Caps',
          sort: false,
          orderBy: 'def'
        },
        {
          id: 7,
          slug: '',
          name: 'Description',
          sort: false,
          orderBy: 'def'
        },
      ],
    };
  },
  computed: {
    _mainData() {
      return this.$store.getters['offerDetails/mainData'];
    },
    _payoutData() {
      // const example = {
      //   "network_offer_payout_revenue_id": 180,
      //   "custom_payout_overwrite": false,
      //   "network_custom_payout_revenue_setting_id": 0,
      //   "payout_type": "cps",
      //   "entry_name": "Base",
      //   "payout_amount": 0,
      //   "payout_percentage": 10,
      //   "is_default": true,
      //   "is_postback_disabled": false,
      //   "is_enforce_caps": false
      // };
      const payouts = this._mainData?.relationship?.payouts?.entries[0];
      if (!payouts) {
        return [];
      }
      return [payouts];
    },
  }
};
</script>