<template>
  <div class="v-collapse">
    <div
      class="v-collapse__header"
      :class="{'v-collapse__header--active': bodyIsOpened}"
      @click="bodyIsOpened = !bodyIsOpened"
    >
      <slot name="header"></slot>
      <icon-arrow-up v-if="showIcon" height="20" width="20" class="icon" />
      <div class="line" v-if="showLine"></div>
    </div>

    <div
      class="v-collapse__body"
      :class="{'v-collapse__body--active': bodyIsOpened}"
      ref="body"
    >
      <div class="v-collapse__content">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import IconArrowUp from "@/js/components/icons/IconArrowUp";

export default {
  name: "VCollapse",
  props: {
    showLine: {
      type: Boolean,
      default: false
    },
    bodyIsVisible: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: true,
    }
  },
  components: { IconArrowUp },
  data() {
    return {
      bodyIsOpened: this.bodyIsVisible,
    };
  },
  mounted() {
    // this.setBodyHeight();
  },
  methods: {
    // setBodyHeight() {
    //   const root = this.$refs.body;
    //   const { height } = root.getBoundingClientRect();
    //   root.style.setProperty('--body-height', height + 'px');
    // },
  },
};
</script>

<style lang="scss" scoped>
.v-collapse {
  width: 100%;

  &__header {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;

    .icon {
      flex-shrink: 0;
      margin: 0 8px;
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
      transition: transform .3s ease-in-out;
    }

    .line {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      &:after {
        content: '';
        height: 1px;
        width: 100%;
        background-color: var(--grey-light-1);
        display: inline-block;
        position: absolute;
      }
    }

    &--active {
      .icon {
        transform: rotate(0);
      }
    }
  }

  &__body {
    --timeout: .15s;
    background-color: var(--white-main);
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height var(--timeout) linear var(--timeout),
    opacity var(--timeout) linear 0s,
    overflow var(--timeout) linear 0s;

    &--active {
      height: 100%; // var(--body-height);
      opacity: 1;
      overflow: visible;
      transition: height var(--timeout) linear,
      opacity var(--timeout) linear var(--timeout),
      overflow var(--timeout) linear var(--timeout);
    }
  }

  &__content {
    padding-top: 20px;
  }
}
</style>